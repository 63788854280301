import { useAuthContext } from '../hooks/useAuthContext'
import { auth } from '../firebase/config'
import { signOut } from 'firebase/auth'
import { useState } from "react"

export const useLogout = () => {
  const { dispatch } = useAuthContext()
  const [loading, setLoading] = useState(false)
  const logout = async () => {
    setLoading(true)
    try {
      await signOut(auth)
      dispatch({ type: 'LOGOUT' })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error.message)
    }
  }
  return { logout, loading }
}
