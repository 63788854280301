import { format } from "date-fns"

export const dashboardPricing = [
    "Enter any month for the past 3 years",

    "Track any of the 12 Bestimate numbers",

    "Enter goals for the next 3 years",
    "Compare against your history",

    "Compare against your industry",

    "Compare against other Bestimate companies",

    "Valuable training on each number",

    "Exclusive Bestie Community access",

    "Live Voice/Chat Support",

    "Coming Soon! Visualization Charts & Graphs",

]

export const techPricing = [
    "Advanced Bestimate dashboard w/ custom metrics ",
    "Profit-Targeted budget automations",

    "30% off Custom Web/Mobile Development",


    "Vendasta SEO/Google Marketing Audit",

    "QuickBooks Online bookkeeping integration",

    "Bento marketing lead tracking integration",

    "Novo Bank budget management integration",

    "Heartbeat community portal integration",

    "Dedicated Bestimate Certified Tech Therapist",

    "Private support voice/text chat for your team",
    "Business Process Audit",

    "Automation Strategy Guide",


    "Customer lifecycle automations",

    "Org chart optimizations",

    "3 years of Bestimate projections & goals",

    "IT systems recommendations",

    "Tech Therapy Podcast interview",

    "Monthly Tech Therapy mastermind ",
]


export const enterPricing = [
    "We’re not just one-trick ponies. If you need to get some serious work done, Bestimate does custom software development. For anything you need to build: websites, e-commerce, funnels, blogs, LMS, CRM, dashboards, mobile apps, data science, databases, APIs, automations, or anything you can imagine… we can solve it in 4 steps: Discover → Design → Develop → Deploy. "
]

export const blogPosts = [
    {
        postTitle: "Post Title",
        img: "/blogs/Image.png",
        date: format(new Date(), "MMM, yyyy"),
        description: `Amet minim mollit non deserunt ullamco est sit aliqua dolor do
        amet sint. Velit officia consequat duis enim velit mollit.
        Exercitation veniam consequat sunt nostrud amet.`
    },
    {
        postTitle: "Post Title",
        img: "/blogs/Image-1.png",
        date: format(new Date(), "MMM, yyyy"),
        description: `Amet minim mollit non deserunt ullamco est sit aliqua dolor do
        amet sint. Velit officia consequat duis enim velit mollit.
        Exercitation veniam consequat sunt nostrud amet.`
    },
    {
        postTitle: "Post Title",
        date: format(new Date(), "MMM, yyyy"),
        description: `Amet minim mollit non deserunt ullamco est sit aliqua dolor do
        amet sint. Velit officia consequat duis enim velit mollit.
        Exercitation veniam consequat sunt nostrud amet.`
    },
    {
        postTitle: "Post Title",
        date: format(new Date(), "MMM, yyyy"),
        description: `Amet minim mollit non deserunt ullamco est sit aliqua dolor do
        amet sint. Velit officia consequat duis enim velit mollit.
        Exercitation veniam consequat sunt nostrud amet.`
    },
    {
        postTitle: "Post Title",
        date: format(new Date(), "MMM, yyyy"),
        description: `Amet minim mollit non deserunt ullamco est sit aliqua dolor do
        amet sint. Velit officia consequat duis enim velit mollit.
        Exercitation veniam consequat sunt nostrud amet.`
    },
    {
        postTitle: "Post Title",
        date: format(new Date(), "MMM, yyyy"),
        description: `Amet minim mollit non deserunt ullamco est sit aliqua dolor do
        amet sint. Velit officia consequat duis enim velit mollit.
        Exercitation veniam consequat sunt nostrud amet.`
    },
]

export const article = {
    title: "Why Bestimate? 7 things you need to learn to achieve business growth.",
    author: "Pace Ellsworth",
    date: format(new Date(), "MMM dd, yyyy"),
    mainContent: `Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam
    consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit
    mollit. Exercitation veniam consequat sunt nostrud amet.
    Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam
    consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit
    mollit.
    Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
    consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua
    dolor do amet sint.
    Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit
    aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit
    non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt
    nostrud amet.
    Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam
    consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit
    mollit. Exercitation veniam consequat sunt nostrud amet.`
}