import { createTheme } from '@mui/material/styles';

export const lightTheme = createTheme({
    palette: {
        mode: 'light',
        common: {
            black: "#212121"
        },
        primary: {
            main: "#042187"
        }
    },
    typography: {
        fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans- serif`
    },
    components: {

    },
});

export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: "#042187"
        }
    },
    components: {
    },
});