import { useEffect, useState } from "react"
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext'
import Register from './pages/register/Register'
import Login from './pages/./login/Login'
import Landing from './pages/landing/Landing'
import Dashboard from './pages/dashboard/Dashboard'
import PasswordReset from './pages/reset/PasswordReset'
import NotFound from './components/NotFound'
import Navbar from './components/Navbar'
import { ThemeProvider } from '@mui/material/styles';
import { lightTheme } from "./theme";
import { SnackbarProvider, useSnackbar } from 'notistack';
import { Close as IconClose } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import ProtectedRoute from './PrivateRoutes'
import Pricing from "./pages/Pricing"
import Blog from "./pages/Blog"
import Article from "./pages/Article"
import Terms from "./pages/Terms"
import Privacy from "./pages/Privacy"

function SnackbarCloseButton({ id }) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton style={{ color: 'white' }} onClick={() => closeSnackbar(id)}>
      <IconClose />
    </IconButton>
  );
}

const App = () => {
  const { user, authIsReady } = useAuthContext()
  const navigate = useNavigate();
  const { pathname } = useLocation()
  const [edit, setEdit] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [dateValue, setDateValue] = useState(new Date());
  const [emptyDataFields, setEmptyDataFields] = useState(0)

  useEffect(() => {
    if (!!user && authIsReady) {
      navigate("/dash")
    }
  }, [user, authIsReady, navigate])

  return (
    <ThemeProvider theme={lightTheme}>
      <SnackbarProvider
        action={key => <SnackbarCloseButton id={key} />}
        hideIconVariant
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className='container'>

          {["/", "/dash", "/login", "/register", "/pricing", "/blog", "/article", "/terms", "/privacy"].includes(pathname) && <Navbar edit={edit} setEdit={setEdit} isSubmit={isSubmit} setIsSubmit={setIsSubmit} dateValue={dateValue} setDateValue={setDateValue} emptyDataFields={emptyDataFields} />}
          <div className='container'>
            <Routes>
              <Route path='/'
                element={<Landing />} />
              <Route path='/dash'
                element={<ProtectedRoute user={user && authIsReady}>
                  <Dashboard edit={edit} setEdit={setEdit} isSubmit={isSubmit} setIsSubmit={setIsSubmit} dateValue={dateValue} setDateValue={setDateValue} emptyDataFields={emptyDataFields} setEmptyDataFields={setEmptyDataFields} />
                </ProtectedRoute>} />
              <Route path='/try'
                element={<Register />} />
              <Route path='/login'
                element={<Login />} />
              <Route path='/password-reset' element={<PasswordReset />} />
              <Route path='/pricing' element={<Pricing />} />
              <Route path='/blog' element={<Blog />} />
              <Route path='/article' element={<Article />} />
              <Route path='/terms' element={<Terms />} />
              <Route path='/privacy' element={<Privacy />} />
              <Route path='*' element={<NotFound />} />
            </Routes>
          </div>
        </div>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

//changes

export default App
