import { useState } from 'react'
import { useAuthContext } from '../hooks/useAuthContext'
import { auth } from '../firebase/config'
import { signInWithEmailAndPassword } from 'firebase/auth'

export const useLogin = () => {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const { dispatch } = useAuthContext()

  const login = async (email, password, navigate) => {
    setError(null)
    setLoading(true)
    try {
      const res = await signInWithEmailAndPassword(auth, email, password)
      dispatch({ type: 'LOGIN', payload: res.user })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setError(error)
    }
  }

  return { error, login, loading }
}
