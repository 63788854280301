import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';

export default function ControlledAccordions({ data, duo }) {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div style={duo && { display: "flex", flexWrap: "wrap" }}>
            {data.map((v, i) => <Accordion key={i} expanded={expanded === v.panel} onChange={handleChange(v.panel)} sx={{ borderTop: "1.5px solid black", boxShadow: "none", mb: 2, width: duo && "50%" }} square>
                <AccordionSummary
                    expandIcon={<AddIcon fontSize="large" />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{ py: 0, px: 0.5, minHeight: "unset", "& div": { m: 0.1, mb: duo && 8 } }}
                >
                    <Typography variant="body1" sx={{ width: '70%', flexShrink: 0, fontWeight: "500" }}>
                        {v.title}
                    </Typography>
                    {/* <Typography sx={{ color: 'text.secondary' }}>{title}</Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                    <Typography color="primary">
                        {v.details}
                    </Typography>
                </AccordionDetails>
            </Accordion>)}
        </div>
    );
}