import { useState } from 'react'
import { useAuthContext } from './useAuthContext'
import { db, auth } from '../firebase/config'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { doc, setDoc } from 'firebase/firestore'

export const useRegister = () => {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const { dispatch } = useAuthContext()

  const addUser = async ({ user: { uid, email } }, other, enqueueSnackbar = false) => {
    setLoading(true)
    try {
      const docRef = doc(db, 'users', uid)
      await setDoc(docRef, {
        email,
        ...other
      })
      setLoading(false)
      enqueueSnackbar && enqueueSnackbar("User Data Updated!", { variant: "success" });
    }
    catch (error) {
      setLoading(false)
      setError(error)
    }
  }
  const addCompany = async ({ uid, email }, other, enqueueSnackbar, handleClose) => {
    setLoading(true)
    try {
      const docRef = doc(db, 'companies', uid)
      await setDoc(docRef, {
        email,
        ...other
      })
      setLoading(false)
      handleClose()
      enqueueSnackbar("Comapny Data Updated!", { variant: "success" });
    }
    catch (error) {
      setLoading(false)
      setError(error)
    }
  }

  const signup = async ({ email, password, ...others }, navigate) => {
    setError(null)
    setLoading(true)
    try {
      const res = await createUserWithEmailAndPassword(auth, email, password)
      await addUser(res, others)
      setLoading(false)
      dispatch({ type: 'LOGIN', payload: res.user })
      navigate("/login")
    } catch (error) {
      setLoading(false)
      setError(error)
    }
  }

  return { error, signup, loading, addUser, addCompany }
}
