import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useTheme } from "@mui/material/styles"
import { Box, TextField, Typography, Tooltip } from '@mui/material';
import { brief } from '../pages/dashboard/constants';

const Circular = ({ title, name, percent, negative, value, onChange, edit }) => {
    const { palette: { primary: { main } } } = useTheme()
    return <CircularProgressbarWithChildren value={Math.abs(percent)}
        strokeWidth={4}
        styles={buildStyles({
            strokeLinecap: "butt",
            pathColor: main,
            trailColor: "#FFFFFF",
        })}
        counterClockwise={percent > 0 ? false : true}

    // style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", borderRadius: "50%" }}
    >
        <Box sx={{ textAlign: "center", p: { xs: 3, md: 1 } }}>
            <Typography variant="h5" color="primary" fontWeight={"500"}>{percent >= 0 ? "+" : ""}{percent}%</Typography>
            <Tooltip title={title} followCursor >
                <Typography variant="body1" fontWeight={"500"}>
                    {brief[title]}
                </Typography>
            </Tooltip>
            {!edit && !!value ? <Typography variant="h5" color="primary" fontWeight={"500"}>{negative ? `-${(value).toLocaleString(undefined, { maximumFractionDigits: 2 })}` : (+value).toLocaleString(undefined, { maximumFractionDigits: 2 })}</Typography>
                : <TextField
                    name={name}
                    label=""
                    variant="standard"
                    autoComplete='off'
                    sx={{ "&:before": { borderBottom: "2px solid rgba(0, 0, 0, 0.42)" } }}
                    inputProps={{
                        sx: {
                            color: theme => theme.palette.primary.main, fontWeight: "500", width: "75px", fontSize: "1.2rem",
                            textAlign: "center",
                            // "&:before": { borderBottom: "2px solid rgba(0, 0, 0, 0.42)" }
                        }
                    }}
                    value={negative && !!value ? `-${(value).toLocaleString(undefined, { maximumFractionDigits: 2 })}` : (value).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                    onChange={onChange}
                />}
        </Box>
    </CircularProgressbarWithChildren>
};

export default Circular;