import { Container, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import Footer from '../components/Footer'
import { article } from './const'

const Article = () => {

    const divideP = article.mainContent.split(".").slice(0, -1)
    let finalP = []
    for (let i = 0; i < divideP.length; i++) {
        finalP.push(`${`${divideP[i]}. `}${i + 1 < divideP.length ? `${divideP[i + 1]}. ` : ""}${i + 2 < divideP.length ? `${divideP[i + 2]}. ` : ""}${i + 3 < divideP.length ? `${divideP[i + 3]}. ` : ""}`)
        i = i + 3;
    }
    return (
        <Container maxWidth={"xl"}>
            <Grid container justifyContent={"center"}>
                <Grid item xs={12} md={10}>
                    <Box py={3} sx={{ width: { xs: "100%", md: "58%" }, mx: "auto" }}>
                        <Typography variant={"h4"} textAlign={"center"} fontWeight={"bold"} >{article.title}</Typography>
                    </Box>
                    <Typography variant={"body1"} textAlign={"center"}>By {article.author}</Typography>
                    <Typography variant={"body1"} textAlign={"center"} pb={3}>{article.date}</Typography>
                    {finalP.map((v, i) => <Typography key={i} variant={"body1"} pb={3} >{v}</Typography>)}
                </Grid>
            </Grid>
            <Footer />
        </Container >
    )
}

export default Article