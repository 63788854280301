import { useState } from 'react'
import { db } from '../firebase/config'
import { doc, updateDoc } from 'firebase/firestore'
import { useSnackbar } from 'notistack'

export const useUpdate = () => {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const { enqueueSnackbar } = useSnackbar();

    const updateCompany = async ({ uid }, other) => {
        setError(null)
        setLoading(true)
        try {
            const docRef = doc(db, 'companies', uid)
            await updateDoc(docRef, {
                ...other
            })
            setLoading(false)
            enqueueSnackbar("Data Updated!", { variant: "success" });
        }
        catch (error) {
            setLoading(false)
            enqueueSnackbar(error, { variant: "error" });
            setError(error)
        }
    }


    return { error, loading, updateCompany }
}
