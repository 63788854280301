import { Box, Button, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { Link, useLocation } from 'react-router-dom'

const iconsPathList = [
  'youtube.png',
  'tiktok.png',
  'linkedin.png',
  'twitter.png',
  'facebook.png',
  'instagram.png',
  'whatsapp.png',
  'telegram.png',
]

const Footer = () => {
  let year = new Date()
  const { pathname } = useLocation()
  return (
    <Container maxWidth='xl'>
      <Box
        display={'flex'}
        justifyContent={pathname !== "/blog" && pathname !== "/article" ? 'space-between' : "center"}
        alignItems={'center'}
        py={2}
      >
        {(pathname !== "/blog" && pathname !== "/article") && <Box>
          <Button variant='text' sx={{ fontSize: '20px' }} component={"a"} href="https://www.bestimate.chat" target="_blank">
            support
          </Button>
          <Button variant='text' sx={{ fontSize: '20px' }} component={Link} to="/article">
            research
          </Button>
          <Button variant='text' sx={{ fontSize: '20px' }}>
            careers
          </Button>
          <Button variant='text' sx={{ fontSize: '20px' }} component={"a"} href="https://www.techtherapypodcast.com" target="_blank">
            podcast
          </Button>
        </Box>}
        <Box>
          <Container sx={{ mt: 2 }}>
            {iconsPathList.map((val, i) => (
              <Box
                component={'img'}
                sx={{ p: 0.5, cursor: 'pointer' }}
                key={i}
                src={'/social/' + val}
                alt={val}
              />
            ))}
          </Container>
        </Box>
      </Box>
      <Typography textAlign='center' fontWeight='500' pb={2}>
        Bestimate, Nuclius LLC &copy;2020 - {year.getFullYear()} All rights
        reserved.
        <Button variant='text' component={"a"} href="/terms">Terms</Button>
        <Button variant='text' component={"a"} href="/privacy">Privacy</Button>
      </Typography>
    </Container>
  )
}

export default Footer
