
import { Box, Container } from "@mui/system"
import { useState, useEffect } from "react"
import { useAuthContext } from "../../hooks/useAuthContext"
import { useCollection } from "../../hooks/useCollection"
import { useUpdate } from "../../hooks/useUpdate"
import { styled } from '@mui/material/styles';
import { Typography, Grid } from "@mui/material";
import CircularComponent from "../../components/CircularComponent"
import { getInitialValue } from "./constants";
import { format } from 'date-fns'

const Dashboard = ({ edit, setEdit, isSubmit, setIsSubmit, dateValue, setEmptyDataFields }) => {
  const { user } = useAuthContext()
  const { documents } = useCollection("companies")
  const { documents: userDoc } = useCollection("users")
  const [state, setState] = useState(null)
  const [dataState, setDataState] = useState(getInitialValue(new Date()))
  const { updateCompany } = useUpdate()
  // const matches = useMediaQuery('(min-width:1700px)');


  useEffect(() => {
    if (state?.dashboardData) {
      const dateIncludedData = state.dashboardData.filter(val => {
        if (!val?.date) {
          return format(dateValue, 'MMM yyyy') === format(new Date(), 'MMM yyyy')
        } else {
          const dataDate = val.date.toDate()
          const dataDateString = dataDate.toDateString()
          return (format(dateValue, 'MMM yyyy') === format(new Date(dataDateString), 'MMM yyyy'))
        }
      })
      !dateIncludedData.length ? setDataState(getInitialValue(dateValue)) : setDataState(dateIncludedData)
    }
  }, [documents, state?.dashboardData, dateValue])

  useEffect(() => {
    if (dataState) {
      let allInOne = [];
      dataState.forEach(v => allInOne = [...allInOne, ...v.data])
      const checkFields = allInOne?.filter(emptyField => !!emptyField.value)
      setEmptyDataFields(checkFields?.length)
    }
  }, [dataState, setEmptyDataFields])

  useEffect(() => {
    const copyUserInfo = userDoc?.find(v => v.id === user.uid)
    const copyCompanyInfo = documents?.find(v => v.id === user.uid)
    setState(prev => ({ ...prev, ...copyUserInfo, ...copyCompanyInfo }))
    setEdit(!copyCompanyInfo?.dashboardData)
  }, [documents, user?.uid, userDoc, setEdit])

  const Img = styled('img')({
    width: "48px",
    height: "48px"
  })

  const handleChange = (e, masterTitle) => {
    const updatedDataState = dataState.map(val => {
      if (val.title === masterTitle) {
        let nestedClone = val.data.map(nestedData => {
          if (nestedData.title === e.target.name) {
            if (e.target.value[e.target.value.length - 1] === ("-")) {
              nestedData.negative = !nestedData?.negative
            }
            nestedData.value = Number(/^.{0,6}$/.test(e.target.value.replace(/\D/g, '')) ? e.target.value.replace(/\D/g, '') : nestedData.value)
            setEdit(true)
            return nestedData
          }
          return nestedData
        })
        nestedClone.date = new Date()
        return { title: val.title, data: nestedClone }
      }
      return val
    })
    setDataState(updatedDataState)
  }


  useEffect(() => {
    if (isSubmit) {
      let finalData = dataState.map(val => ({ ...val, date: dateValue }))
      const prevData = state?.dashboardData?.map(v => {
        const dataDate = v.date.toDate()
        const dataDateString = dataDate.toDateString()
        return { ...v, date: new Date(dataDateString) }
      })
      let combinedData = prevData ? [...finalData, ...prevData] : [...finalData]
      let result = combinedData.reduce(function (prev, curr, i) {
        let flag = false;
        if (i < 1) {
          prev.push(curr)
        } else {
          prev.forEach((v) => {
            if ((format(v.date, 'MMM yyyy') === format(curr.date, 'MMM yyyy')) && v.title === curr.title) {
              flag = true
            }
          })
          !flag && prev.push(curr)
        }
        return prev;
      }, []);
      updateCompany(user, { dashboardData: result })
      setIsSubmit(false)
      setEdit(false)
    }
  }, [isSubmit, setEdit, setIsSubmit, user, updateCompany, dataState, dateValue, state?.dashboardData])

  return (
    <Container //disableGutters={matches} maxWidth={"xl"}
    >
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {state?.dashboardData ? <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", pt: 1 }}>
          {state?.logo && <Img alt="" src={state.logo} />}
          <Box sx={{ px: 1 }}>
            <Box sx={{ display: "flex", alignItems: "baseline" }}>
              {state?.name && <Typography variant="h6" >{state?.name}&nbsp;</Typography>}
              {state?.website && <Typography variant="body2" color="primary" fontWeight={"500"} component="a" href={`https://${state?.website}`} target="_blank" sx={{ textDecoration: "none" }}>{state?.website}</Typography>}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {state?.displayName && <Typography variant="body2" fontWeight={"500"} >{state?.displayName},&nbsp;</Typography>}
              {state?.title && <Typography variant="body2" fontWeight={"500"} >{state?.title}</Typography>}
            </Box>
          </Box>
        </Box> : <Box sx={{ p: 1 }}>
          <Typography variant="body1" fontWeight={"bold"}>Click Edit to the right of the Month to start entering your data</Typography>
        </Box>
        }
      </Box>
      <Grid container spacing={{ xs: 2, xl: 5 }} mt={{ xs: 1, xl: 2 }}>
        {dataState?.map((v, i) => (
          <Grid key={i} item xs={12} md={6} xl={4} container>
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight={"500"} textAlign="center">{v.title}</Typography>
            </Grid>
            {v?.data?.map((val, i) => (
              <Grid key={i} item xs={6}>
                <Box sx={{ maxWidth: 182, minWidth: 160, m: { xs: 1, md: 3 }, boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.25)", borderRadius: "50%" }}>
                  <CircularComponent negative={val?.negative} title={val.title} value={val.value} percent={val.percent} name={val.title} onChange={(e) => handleChange(e, v.title)} edit={edit} />
                </Box>
              </Grid>))}
          </Grid>)
        )}
      </Grid>
    </Container>
  )
}

export default Dashboard
