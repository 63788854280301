import { Container } from '@mui/system'
import React from 'react'
import Footer from '../components/Footer'

function Terms() {
    return (
        <Container maxWidth={"xl"}>
            <div name="termly-embed" data-id="06f99fa7-4813-4489-8e1c-80c85a0050ae" data-type="iframe"></div>
            <Footer />
        </Container>
    )
}

export default Terms