export const getInitialValue = (date) => {
    return [
        {
            title: "Marketing",
            date: date,
            data: [{
                title: "CLV - Customer Lifetime Value",
                value: 0,
                percent: 0
            },
            {
                title: "CAC - Client Acquisition Cost",
                value: 0,
                percent: 0
            }
            ]
        },
        {
            title: "Finance",
            date: date,
            data: [{
                title: "Top Line Revenue",
                value: 0,
                percent: 0
            },
            {
                title: "Total Expenses",
                value: 0,
                percent: 0
            },
            ]
        },
        {
            title: "Sales",
            date: date,
            data: [{
                title: "# of Qualified Leads",
                value: 0,
                percent: 0
            },
            {
                title: "# of New Customers",
                value: 0,
                percent: 0
            },
            ]
        },
        {
            title: "Technology",
            date: date,
            data: [{
                title: "Domain Authority",
                value: 0,
                percent: 0
            },
            {
                title: "Technology Budget",
                value: 0,
                percent: 0
            },
            ]
        },
        {
            title: "Operations",
            date: date,
            data: [{
                title: "Payroll Costs",
                value: 0,
                percent: 0
            },
            {
                title: "COGS - Cost of Goods Sold",
                value: 0,
                percent: 0
            },
            ]
        },
        {
            title: "Admin",
            date: date,
            data: [{
                title: "Owner Hours/Week",
                value: 0,
                percent: 0
            },
            {
                title: "Net Profit Margin",
                value: 0,
                percent: 0
            },
            ]
        },
    ]
}

export const brief = {
    "CLV - Customer Lifetime Value": "CLV",
    "CAC - Client Acquisition Cost": "CAC",
    "Top Line Revenue": "Top Line Revenue",
    "Total Expenses": "Total Expenses",
    "# of Qualified Leads": "New Leads",
    "# of New Customers": "New Cliients",
    "Domain Authority": "Domain Authority",
    "Technology Budget": "IT Budget",
    "Payroll Costs": "Payroll",
    "COGS - Cost of Goods Sold": "COGS",
    "Owner Hours/Week": "Owner Hrs/Wk",
    "Net Profit Margin": "Net Profit Margin",
}