import { Container, Divider, Grid, Typography } from "@mui/material"
import { Box } from "@mui/system"
import React from "react"
import { useState } from "react"
import { Link } from "react-router-dom"
import Footer from "../components/Footer"
import { blogPosts } from "./const"

const Blog = () => {
  const [recentBlogs, setRecentBlogs] = useState(true)

  return (
    <Container maxWidth={"xl"}>
      <Grid container justifyContent={"center"}>
        {recentBlogs ? (
          <Grid item xs={12} md={7}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                alt="site-logo"
                src="/bestimate 2.png"
                style={{ cursor: "pointer", zIndex: 1 }}
              />
              <Box
                sx={{
                  px: 1,
                  cursor: "pointer",
                  textDecoration: "none",
                  color: "#000000",
                  zIndex: 1,
                }}
                component={Link}
                to="/"
              >
                <Typography variant="h3" fontWeight={"400"}>
                  Bestimate
                </Typography>
                <Typography variant="body1" fontWeight={"400"}>
                  Simpler data so you can play
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6" fontWeight={"500"} pt={5}>
                Recent Blogs
              </Typography>
              <Typography
                variant="body1"
                fontWeight={"500"}
                sx={{ color: "#00A8CC", cursor: "pointer" }}
                pt={5}
                onClick={(_) => setRecentBlogs(false)}
              >
                View all
              </Typography>
            </Box>
            {blogPosts
              .filter((v, i) => i < 2)
              .map((v, i) => (
                <Box ket={i} pt={5}>
                  <Box ket={i} pb={3} display="flex">
                    <Box
                      component={"img"}
                      src={v.img}
                      alt={v.postTitle}
                      mr={2}
                      sx={{ width: 270, height: 190 }}
                    />
                    <Box>
                      <Typography variant="h5" fontWeight={"bold"}>
                        {v.postTitle}
                      </Typography>
                      <Typography variant="caption">{v.date}</Typography>
                      <Typography variant="body1" py={3}>
                        {v.description}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider />
                </Box>
              ))}
          </Grid>
        ) : (
          <Grid item xs={12} md={8}>
            <Typography variant="h4" fontWeight={"500"} pt={5}>
              Blogs
            </Typography>
            {blogPosts.map((v, i) => (
              <Box ket={i} pt={5}>
                <Typography variant="h5" fontWeight={"bold"}>
                  {v.postTitle}
                </Typography>
                <Typography variant="caption">{v.date}</Typography>
                <Typography variant="body1" py={3}>
                  {v.description}
                </Typography>
                <Divider />
              </Box>
            ))}
          </Grid>
        )}
      </Grid>
      <Footer />
    </Container>
  )
}

export default Blog
