import { Box, Container } from "@mui/system"
import { styled } from '@mui/material/styles';
import { Typography, Grid, useMediaQuery, Avatar, IconButton, TextField, Button } from "@mui/material";
import CircularComponent from "../../components/CircularComponent"
import { dummyData, team, clients, data, bestimateData } from "./constant";
import Footer from "../../components/Footer";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns'
import EditIcon from '@mui/icons-material/Edit';
import ControlledAccordions from "../../components/Accordian";





const Landing = () => {
  const matches = useMediaQuery('(min-width:1700px)');
  const Img = styled('img')({
    width: "48px",
    height: "48px"
  })

  return (
    <Container
      disableGutters={matches} maxWidth={"xl"}
    >
      <Grid container spacing={0}>
        <Grid item xs={12} md={5}>
          <Box mx={2} my={4}>
            <Typography variant="h1" textTransform={"uppercase"} fontWeight={"bold"} >simpler</Typography>
            <Typography variant="h1" textTransform={"uppercase"} fontWeight={"400"} >Dash</Typography>
            <Typography variant="h1" textTransform={"uppercase"} fontWeight={"400"} >boards</Typography>
            <Typography variant="h1" textTransform={"uppercase"} fontWeight={"bold"} >to grow</Typography>
            <Typography variant="h1" textTransform={"uppercase"} fontWeight={"400"} >your</Typography>
            <Typography variant="h1" textTransform={"uppercase"} fontWeight={"400"} >business</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          <Box sx={{ display: "flex", justifyContent: "center" }} my={4}>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", pt: 1 }}>
              <Img alt="" src={"/nuclius.png"} />
              <Box sx={{ px: 1 }}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <Typography variant="h6" >Your Company&nbsp;</Typography>
                  <Typography variant="body2" color="primary" fontWeight={"500"} component="a" href={"https://www.nuclius.com"} target="_blank" sx={{ textDecoration: "none" }}>yourwebsite.here</Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="body2" fontWeight={"500"} >You McYouTheBoss,&nbsp;</Typography>
                  <Typography variant="body2" fontWeight={"500"} >CEO</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Grid container spacing={{ xs: 2 }} mt={{ xs: 1 }}>
            {dummyData?.map((v, i) => (
              <Grid key={i} item xs={12} md={6} container>
                <Grid item xs={12}>
                  <Typography variant="body1" fontWeight={"500"} textAlign="center">{v.title}</Typography>
                </Grid>
                {v?.data?.map((val, i) => (
                  <Grid key={i} item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
                    <Box sx={{ maxWidth: 182, minWidth: 160, m: { xs: 1, md: 3 }, boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.25)", borderRadius: "50%" }}>
                      <CircularComponent title={val.title} value={val.value} percent={val.percent} />
                    </Box>
                  </Grid>))}
              </Grid>))}
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} xl={3} mt={5}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ px: 1, textAlign: "center" }}>
              <Typography variant="body2">Bestimate</Typography>
              <Box sx={{ borderRadius: "30px", border: theme => `1px solid ${theme.palette.primary.main}`, py: 0.5, px: 1.3 }}>
                <Typography variant="body1" color="primary" fontWeight={"bold"}>
                  $500k-$1M
                </Typography>
              </Box>
            </Box>
            <Box sx={{ px: 1, textAlign: "center" }}>
              <Typography variant="body2">Certainty</Typography>
              <Box sx={{ p: 0.7 }}>
                <Typography variant="body1" fontWeight={"bold"}>
                  84%
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
            <Box sx={{ position: "relative" }}>
              <Typography variant="body2" fontWeight={"500"} textAlign="center">Month</Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns} >
                <DatePicker
                  views={['year', 'month']}
                  minDate={new Date('2017-01-01')}
                  maxDate={new Date('2027-06-01')}
                  value={new Date()}
                  // onChange={(newValue) => {
                  //   setDateValue(newValue)
                  // }}
                  // disabled={edit}
                  OpenPickerButtonProps={{ sx: { position: "absolute", left: 0, width: "100%", "& svg": { visibility: "hidden" }, py: 1.3, zIndex: 1 } }}
                  renderInput={(params) => <TextField {...params} value={format(new Date(), 'MMM yyyy')} helperText={null} sx={{ "& .MuiOutlinedInput-root": { borderRadius: 30, } }} inputProps={{ sx: { fontWeight: "500", color: theme => theme.palette.primary.main, py: 1.3, pr: 0, pl: { xs: 0, md: "14px" }, textAlign: "center", width: "100px" } }} />}
                />
              </LocalizationProvider>
            </Box>
            {/* {!edit &&  */}
            <Box sx={{ px: 1, textAlign: "center" }}>
              <Typography variant="body2" fontWeight={"500"}>Edit</Typography>
              <Box sx={{ borderRadius: "30px", border: theme => `1px solid ${theme.palette.primary.main}` }}>
                <IconButton sx={{ p: 1.1 }} //onClick={_ => setEdit(true)}
                >
                  <EditIcon color="primary" fontSize='small' />
                </IconButton>
              </Box>
            </Box>
            <Box sx={{ px: 1, }}>
              <Typography variant="body2" fontWeight={"500"} sx={{ ml: 1.5 }}>Compare to</Typography>
              <Box sx={{ borderRadius: "30px", border: theme => `1px solid ${theme.palette.primary.main}`, py: 0.8, px: 1.5, width: 120 }}>
                <Typography variant="body1" color="primary" fontWeight={"bold"}>
                  Your Average
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={8} xl={9} mt={8}>
          <Typography variant="h4" fontWeight="500" textTransform="uppercase" mb={1}>
            Welcome to Bestimate
          </Typography>
          <Typography variant="body1" fontWeight="400" >
            Bestimate is where you track your business vitals – and see how they compare to the past and the competition. It trains you to focus on numbers that grow your business. After all, what’s measured improves, and what’s reported improves faster.
            It’s a gentle breeze, and all free today...
          </Typography>
          <Button variant="contained" sx={{ borderRadius: 30, fontWeight: "500", fontSize: "1rem", mt: 2 }}>
            30-day free trial
          </Button>
        </Grid>

        <Grid item xs={12} md={7} xl={8} my={8}>
          <Typography variant="h4" fontWeight="500" textTransform="uppercase" mb={1} >
            Bestimate has what businesses crave
          </Typography>
          <Typography variant="body1" fontWeight="400" >
            Other systems wait for you to tell them what you want. Bestimate is different. We use a clean interface to track 12 delicious vital numbers. If you know them, you can grow.
          </Typography>
        </Grid>
        <Grid item xs={12} md={5} xl={4} mt={3} display="flex" alignItems={"center"}>
          {dummyData.filter((v, id) => id < 1)?.map((v, i) => (v?.data?.map((val, i) => (
            <Grid key={i} item xs={6} sx={{ display: "flex", justifyContent: i ? "flex-start" : "flex-end" }}>
              <Box sx={{ maxWidth: 170, minWidth: 160, m: { xs: 1, }, boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.25)", borderRadius: "50%" }}>
                <CircularComponent title={val.title} value={val.value} percent={val.percent} />
              </Box>
            </Grid>))))}
        </Grid>
        <Grid item xs={12} mt={10} px={{ md: 25 }}>
          <ControlledAccordions data={bestimateData} duo />
        </Grid>
        <Grid item xs={12} md={8} xl={9} mt={5}>
          <Typography variant="h4" fontWeight="500" textTransform="uppercase" mb={1}>
            What you don’t know is hurting you
          </Typography>
          <Typography variant="body1" fontWeight="400" >
            Businesses that suck at these numbers need therapy. So we invented a discipline: tech therapy. Check out our podcast on the subject where we interview business owners.
            We turned the discipline into our done-for-you service: Bestimate Tech Therapy. The same day you sign up, our Certified Tech Therapists join your team to start systematizing and automating away the busy work so it can’t hurt you any more. So take a step. Get your data today so you can play.
          </Typography>
          <Button variant="contained" sx={{ borderRadius: 30, fontWeight: "500", fontSize: "1rem", mt: 2 }}>
            Get some help
          </Button>
        </Grid>
        <Grid item xs={12} md={4} xl={3} mt={8}>
          <Box component="img" src="/Frame 1.png" pr={5} pt={5} />
        </Grid>
        <Grid item xs={12} md={4} xl={3} mt={8}>
          <Box component="img" src="/Frame 2.png" pr={5} width={"-webkit-fill-available"} />
        </Grid>
        <Grid item xs={12} md={8} xl={9} mt={8}>
          <Typography variant="h4" fontWeight="500" textTransform="uppercase" mb={1}>
            Custom Web &amp; Mobile App Development
          </Typography>
          <Typography variant="body1" fontWeight="400" >
            We’re not just one-trick ponies. Now, if you need to get some serious work done, Bestimate also does custom software development. For anything you need to build: websites, e-commerce, funnels, blogs, LMS, CRM, dashboards, mobile apps, data science, databases, APIs, automations, or anything you can imagine… we can solve it in 4 steps.
          </Typography>
          <Button variant="contained" sx={{ borderRadius: 30, fontWeight: "500", fontSize: "1rem", mt: 2 }}>
            Book a FREE Strategy Call
          </Button>
        </Grid>
        <Grid item xs={12} mt={10} textAlign="center">
          <Typography variant="h4" fontWeight="500" textTransform="uppercase" mb={1} >
            This is a mere fraction of our clients
          </Typography>
          <Box sx={{ width: "90%", mx: "auto" }}>
            {clients
              .map((v, i) => <Box key={i} component="a" sx={{ textDecoration: "none" }} target="_blank" href={v.url}>
                <Box key={i} component="img" alt={v} src={`/clients/${v.src}.png`} pr={5} py={6} sx={{ width: "165px" }} />
              </Box>)
            }
          </Box>

        </Grid>
        <Grid item xs={12} mt={10} textAlign="center">
          <Typography variant="h4" fontWeight="500" textTransform="uppercase" mb={1} >
            the Bestimate Team - Your Besties
          </Typography>
          <Box sx={{ width: "90%", mx: "auto", display: "flex", justifyContent: "center" }}>
            {team
              .map((v, i) => <Box key={i} textAlign="center" m={3}>
                <Avatar key={i} src={`${v.src}`} alt={v.name} pr={5} py={6} sx={{ width: 100, height: 100, mx: "auto" }} />
                <Typography variant="h6">{v.name}</Typography>
                <Typography variant="body1">{v.position}</Typography>
                <Typography variant="body2">{v.exp}</Typography>
                <Typography variant="caption">{v.history}</Typography>
              </Box>
              )}
          </Box>

        </Grid>

        <Grid item xs={12} md={8} xl={9} mt={8}>
          <Typography variant="h4" fontWeight="500" textTransform="uppercase" mb={1}>
            Become a Bestie
          </Typography>
          <Typography variant="body1" fontWeight="400" >
            Try Bestimate free today and automatically join our Support chat on for founders and entrepreneurs at Bestimate.Chat. Join training events, get real time voice/chat support, and see all our extensive how-tos and docs. Or you could just click this comically large button:
          </Typography>
          <Button variant="contained" sx={{ borderRadius: 30, fontWeight: "500", fontSize: "1rem", mt: 2 }}>
            Set yourself down here - the front door’s unlocked
          </Button>
        </Grid>
        <Grid item xs={12} md={4} xl={3} mt={8}>
          <Box component="img" src="/Frame 1.png" pr={5} pt={5} />
        </Grid>
        <Grid item xs={12} mt={3}>
          <Typography variant="h4" fontWeight="500" textTransform="uppercase" my={5}>
            Frequently Asked Questions
          </Typography>
          <ControlledAccordions data={data} />
        </Grid>

      </Grid>
      <Footer />
    </Container>
  )
}

export default Landing;
