import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBv2V6lnjEI5eGBSiA6hpqiykjJFtX0idQ',
  authDomain: 'nu-bestimate.firebaseapp.com',
  projectId: 'nu-bestimate',
  storageBucket: 'nu-bestimate.appspot.com',
  messagingSenderId: '148644866886',
  appId: '1:148644866886:web:faef14b99c895aa7fc1347',
  measurementId: 'G-PPH5YECLF7',
}

initializeApp(firebaseConfig)

const db = getFirestore()
const auth = getAuth()
const bucket = getStorage()

export { db, auth, bucket }
