import React, { useEffect, useState } from "react";
import { Box, Typography, Container, Button, TextField, Grid, Stack, Alert, CircularProgress } from "@mui/material"
import { styled, useTheme } from "@mui/material/styles"
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useLogin } from "../../hooks/useLogin";

const FormBox = styled(Box)(({ theme }) => ({
  borderRadius: "10px",
  border: "1px solid #B0B4B4",
  boxSizing: "border-box",
  marginTop: `${theme.spacing(2)}`,
  padding: `${theme.spacing(3)}`,

  "& .MuiFormControl-root:not(:first-of-type)": {
    marginTop: theme.spacing(2)
  },
  "& .checkboxContainer": {
    display: "flex",
    alignItems: "center",
    "& .MuiCheckbox-root": {
      paddingLeft: "1px",
    },
    "& .MuiTypography-root": {
      fontWeight: "bold",
      margin: `${theme.spacing(2)} 0`
    },
  },
  "& button": {
    fontWeight: "bold",
    "&:last-of-type": {
      marginTop: "12px"
    }
  }
}));

const Login = () => {
  const { login, error, loading } = useLogin();
  const { enqueueSnackbar } = useSnackbar();
  const { palette: { mode } } = useTheme()



  const initialState = {
    email: "",
    password: "",
    rememberMe: false,
  }

  const [state, setState] = useState(initialState)

  const handleChange = (evt) => {
    const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value
    });
  };

  const hanldeSubmit = () => {
    if (!state.email || !state.password) {
      return enqueueSnackbar("Please fill the all the fields", { variant: "error" });
    }
    login(state.email, state.password)
  }

  useEffect(() => {
    return () => {
      if (error?.message) {
      }
    }
  }, [error?.message])

  return (
    <Container >
      <Grid container spacing={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center", my: 5 }}>
        <Grid item xs={12} sm={7} md={5}>
          <Typography variant="h1" sx={{ mb: 2, fontSize: "1.8rem", fontWeight: "bold" }}>Login</Typography>
          {error?.message && (
            <Stack spacing={2} sx={{ mt: 2 }}>
              <Alert
                severity={error?.message?.variant}
                variant={mode === "dark" ? "filled" : "standard"}
                icon={false}
                onClose={_ => { }}
              >
                {error?.message.toString()}
              </Alert>
            </Stack>
          )}
          <FormBox>
            <TextField
              id="loginEmail"
              label="Email"
              variant="outlined"
              size="small"
              fullWidth
              name="email"
              value={state.email}
              onChange={handleChange}
            />
            <TextField
              id="loginPass"
              label="Password"
              variant="outlined"
              size="small"
              type="password"
              fullWidth
              name="password"
              value={state.password}
              onChange={handleChange}
            />
            <Button variant="contained" fullWidth color="primary" onClick={hanldeSubmit} disabled={loading} startIcon={loading && <CircularProgress size={20} />}>Login</Button>
          </FormBox>
          <Typography variant="body2" sx={{ fontWeight: "bold", my: 1.5, textAlign: "end", color: theme => theme.subText }}>Don’t have an account? &nbsp;
            <Typography variant="body2" component={Link} sx={{ color: theme => theme.subText, fontWeight: "bold" }} to="/try">
              Create an account
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Login;