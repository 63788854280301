import { Container } from '@mui/system'
import React from 'react'
import Footer from '../components/Footer'

function Privacy() {

    return (
        <Container maxWidth={"xl"}>
            <div name="termly-embed" data-id="d71af248-dc13-4323-990c-6c238ac72804" data-type="iframe"></div>
            <Footer />
        </Container>
    )
}

export default Privacy


