export let dummyData = [
    {
        title: 'Marketing',
        data: [
            {
                title: 'CLV - Customer Lifetime Value',
                value: ((Math.floor(Math.random() * (100000 - 10000)) + 10000) * 0.1)
                    .toFixed(0)
                    .toString(),
                percent: Math.floor(Math.random() * (50 - -5)) + -5,
            },
            {
                title: 'CAC - Client Acquisition Cost',
                value: ((Math.floor(Math.random() * (100000 - 10000)) + 10000) * 0.01)
                    .toFixed(0)
                    .toString(),
                percent: Math.floor(Math.random() * (50 - -5)) + -5,
            },
        ],
    },
    {
        title: 'Finance',
        data: [
            {
                title: 'Top Line Revenue',
                value: (
                    Math.floor(Math.random() * (100000 - 10000)) + 10000
                ).toString(),
                percent: Math.floor(Math.random() * (50 - -5)) + -5,
            },
            {
                title: 'Total Expenses',
                value: (
                    (Math.floor(Math.random() * (100000 - 10000)) + 10000) *
                    (Math.floor(Math.random() * (1 - 0.8)) + 0.8)
                )
                    .toFixed(0)
                    .toString(),
                percent: Math.floor(Math.random() * (50 - -5)) + -5,
            },
        ],
    },
    {
        title: 'Sales',
        data: [
            {
                title: '# of Qualified Leads',
                value: (Math.floor(Math.random() * (100 - 10)) + 10).toString(),
                percent: Math.floor(Math.random() * (50 - -5)) + -5,
            },
            {
                title: '# of New Customers',
                value: (Math.floor(Math.random() * (40 - 4)) + 4).toString(),
                percent: Math.floor(Math.random() * (50 - -5)) + -5,
            },
        ],
    },
    {
        title: 'Technology',
        data: [
            {
                title: 'Domain Authority',
                value: Math.floor(Math.random() * 101).toString(),
                percent: Math.floor(Math.random() * (50 - -5)) + -5,
            },
            {
                title: 'Technology Budget',
                value: (Math.floor(Math.random() * 100000) * 0.1).toFixed(0).toString(), //(dummyData?.find(val => val.title === "Finance").data[1].value),
                percent: Math.floor(Math.random() * (50 - -5)) + -5,
            },
        ],
    },
    {
        title: 'Operations',
        data: [
            {
                title: 'Payroll Costs',
                value: (
                    (Math.floor(Math.random() * (100000 - 10000)) + 10000) *
                    (Math.floor(Math.random() * (1 - 0.8)) + 0.8) *
                    0.5
                )
                    .toFixed(0)
                    .toString(),
                percent: Math.floor(Math.random() * (50 - -5)) + -5,
            },
            {
                title: 'COGS - Cost of Goods Sold',
                value: (
                    (Math.floor(Math.random() * (100000 - 10000)) + 10000) *
                    (Math.floor(Math.random() * (1 - 0.8)) + 0.8) *
                    0.3
                )
                    .toFixed(0)
                    .toString(),
                percent: Math.floor(Math.random() * (50 - -5)) + -5,
            },
        ],
    },
    {
        title: 'Admin',
        data: [
            {
                title: 'Owner Hours/Week',
                value: Math.floor(Math.random() * 101).toString(),
                percent: Math.floor(Math.random() * (50 - -5)) + -5,
            },
            {
                title: 'Net Profit Margin',
                value: Math.floor(Math.random() * 101).toString(),
                percent: Math.floor(Math.random() * (50 - -5)) + -5,
            },
        ],
    },
]

export const team = [
    {
        name: 'Pace Ellsworth',
        position: 'Founder, COO',
        exp: 'Automation Lunatic. System Solver. Data Whisperer.',
        src: '/team/pace.png',
        history:
            'History: Cardinal Peak, GetLabs, Admiral, BloomTech, Pacific Outdoor Living, Logitech, Heleum',
    },
    {
        name: 'Pacifico Soldati',
        position: 'Founder, COO',
        exp: 'JD/MBA. Productivity Paragon. Metrics Miracle Worker.',
        src: '/team/Pacifico.png',
        history:
            'History: Heroic Hearts, Tabula Rasa, Pyuriti, Veterans Legal Institute, Peaceful Soldiers',
    },
    {
        name: 'Bernhard Hamaker',
        position: 'Founder, CMO',
        exp: 'SEO Sorcerer. PPC Pedigree. Motivation Detector.',
        src: '/team/bern.png',
        history:
            'History: Strait Realty, Young Living, Riot, Skipio, Japanese: The Game, ReachLocal',
    },
    {
        name: 'Zac Smith ',
        position: 'Founder, CTO',
        exp: 'PhD Candidate. Node Wizard. Probably Darth Vader.',
        src: '/team/zac.png',
        history: 'History: Code Shock, BloomTech, New Mexico State University',
    },
]

export const data = [
    {
        title: "What is Bestimate?",
        details: "Bestimate is a simpler way for businesses to start tracking and improving important metrics. You can manage your dashboard yourself (Dashboard), let us manage it for you and automate your business over time (Tech Therapy), or contact us to do a custom digital solution, like a web or mobile app (Enterprise).",
        panel: "panel1"
    },
    {
        title: "What is Bestimate Dashboard?",
        details: "Dashboard is a simpler tool for tracking 12 important business numbers. It also calculates what your business might be worth to an investor. You can track numbers for the past 3 years and the next 3 years and compare with your own history or other businesses. See our pricing page for more information.",
        panel: "panel2"
    },
    {
        title: "What is Bestimate Tech Therapy?",
        details: "Tech Therapy helps businesses automate to give their owners and employees time freedom. For a month-to-month service, we put experts on your team to automate everything. This team is led by a Certified Tech Therapist. See our pricing page for more information.",
        panel: "panel3"
    },
    {
        title: "What is Bestimate Enterprise?",
        details: "Enterprise is our service for bringing your digital project to life. We can build anything in a React-Node stack along with extensive experience with APIs, databases, servers, WordPress, PHP, Python, and just about anything else. See our pricing page for more information.",
        panel: "panel4"
    },
    {
        title: "What is Bestimate.Chat (Support)?",
        details: "Bestimate.Chat is our white-labeled Heartbeat.chat community for our Knowledge Base, Support tickets, Private groups, Events, and a LOT more. Check it out if you haven’t yet, it’s free to join for anyone.",
        panel: "panel5"
    },
    {
        title: "What is Bestimate.Info (Research)?",
        details: "Bestimate.Info is our Research page where we publish insights into Bestimate data. In the future, we plan to allow Besties to share their company profiles there so the public can see it indexed.",
        panel: "panel6"
    },
    {
        title: "What is Bestimate.Works (Careers)?",
        details: "Bestimate.Works is our career site for becoming a Certified Tech Therapist. Tech Therapists use Bestimate to help coach businesses through automating their processes to make them more successful.",
        panel: "panel7"
    },
    {
        title: "What is the Tech Therapy Podcast?",
        details: "The Tech Therapy Podcast (at TechTherapyPodcast.com) is where we speak with business owners one on one to understand how automation is affecting their business and what sets them apart as a business.",
        panel: "panel8"
    },
]

export const bestimateData = [
    {
        title: "Customer Lifetime Value (CLV)",
        details: "How much money you get from one client over all time.",
        panel: "panel1"
    },
    {
        title: "Client Acquisition Cost (CAC)",
        details: "The REAL total cost (don’t forget payroll) of bringing in a new client.",
        panel: "panel2"
    },
    {
        title: "Qualified Leads",
        details: "Total prospects in your target market who express interest each month.",
        panel: "panel3"
    },
    {
        title: "New Customers",
        details: "People that actually start paying you for the first time.",
        panel: "panel4"
    },
    {
        title: "Payroll Costs",
        details: "What you pay your internal team in a month.",
        panel: "panel5"
    },
    {
        title: "Cost of Goods Sold",
        details: "The total variable cost per sale in materials and people.",
        panel: "panel6"
    },
    {
        title: "Top Line Revenue",
        details: "All the money you make in a month from any source.",
        panel: "panel7"
    },
    {
        title: "Total Expenses",
        details: "All the money you spend in a month for any reason.",
        panel: "panel8"
    },
    {
        title: "Domain Authority",
        details: "A fancy score for how famous your website is.",
        panel: "panel9"
    },
    {
        title: "Technology Budget",
        details: "The total cost of software and hardware tools, including all the subscriptions you keep forgetting to cancel.",
        panel: "panel10"
    },
    {
        title: "Owner Hours/Week",
        details: "The unsung death knell of every business. How many hours the owner spends keeping it running.",
        panel: "panel11"
    },
    {
        title: "Net Profit Margin",
        details: "The percentage of surplus revenue compared to expenses. The most important number for your Bestimate.",
        panel: "panel12"
    },
]

export const clients = [
    { src: 'IMAX svg', url: 'https://www.imax.com' },
    { src: 'Best Buy svg', url: 'https://www.bestbuy.com' },
    { src: 'CVS Pharmacy svg', url: 'https://www.cvs.com/' },
    { src: 'Bloom Institute of Technology - png', url: 'https://www.bloomtech.com' },
    { src: 'Frame 111', url: 'https://www.cardinalpeak.com' },
    { src: 'CanYou', url: 'https://www.pacificoutdoorliving.com' },
    { src: 'CardinalPeak', url: 'https://forcecabinets.com' },
    { src: 'CreativeFish', url: 'https://canyou.app' },
    { src: 'Force', url: 'https://ryzerewards.com' },
    { src: 'GoRoam', url: 'https://goroam.tech' },
    { src: 'MBUYU', url: 'https://mbuyu.org' },
    { src: 'Ryze', url: 'https://creativefishstudio.com' },
]
