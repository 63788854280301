import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'
import { useAuthContext } from '../hooks/useAuthContext'
import { useCollection } from '../hooks/useCollection'
import { useLogout } from '../hooks/useLogout'
import { Box, Typography, IconButton, List, ListItem, ListItemText, ListItemIcon, CircularProgress, Button, TextField, Container } from '@mui/material';
import Drawer from '@mui/material/Drawer'
import CloseIcon from '@mui/icons-material/Close';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Modal from '@mui/material/Modal';
import Settings from "./Settings"
import DoneIcon from '@mui/icons-material/Done';
import useMediaQuery from '@mui/material/useMediaQuery';
import Upgrade from './Upgrade';
import EditIcon from '@mui/icons-material/EditOutlined';
import { useUpdate } from '../hooks/useUpdate';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns'
import MenuIcon from '@mui/icons-material/Menu';

const sideTextProps = { color: "primary", fontWeight: "500", fontSize: 20, textAlign: "center", textTransform: "uppercase", py: 0.3 }
const iconsPathList = ["youtube.png", "tiktok.png", "linkedin.png", "twitter.png", "facebook.png", "instagram.png", "whatsapp.png", "telegram.png"]

const Navbar = ({ edit, setEdit, isSubmit, setIsSubmit, dateValue, setDateValue, emptyDataFields }) => {
  const matches = useMediaQuery('(max-width:600px)');
  const { user } = useAuthContext()
  const { documents } = useCollection("users")
  const { logout, loading } = useLogout()
  const [drawer, setDrawer] = useState(false)
  const [currentUser, setCurrentUser] = useState(null)
  const [open, setOpen] = useState(false);
  const [openUpgrade, setOpenUpgrade] = useState(false);
  const { pathname } = useLocation()
  const { loading: saveLoading } = useUpdate()
  // const matchesNav = useMediaQuery('(min-width:1500px)');

  const handleSave = async () => {
    setIsSubmit(true)
  }
  const handleCancel = async () => {
    setEdit(false)
    setIsSubmit(false)
  }

  const handleOpen = () => {
    setDrawer(false)
    setOpen(true)
  };
  const handleClose = () => setOpen(false);
  const handleOpenUpgrade = () => {
    setDrawer(false)
    setOpenUpgrade(true)
  };
  const handleCloseUpgrade = () => setOpenUpgrade(false);

  useEffect(() => {
    setCurrentUser(documents?.find(v => v.id === user?.uid))
  }, [documents, user?.uid])

  const handleSubmit = () => {
    setEdit(false)
    logout();
  }

  const ListBasic = () => (
    <Box
      sx={{ width: 330 }}
      role="presentation"
    >
      <Box sx={{ display: "flex", p: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img alt="site-logo" src="/bestimate 3.png" />
          <Box sx={{ px: 1, textDecoration: "none", color: "#000000" }} component={Link} to="/">
            <Typography variant="h4" fontWeight="400">Bestimate</Typography>
            <Typography variant="body2" fontWeight="400">Data today so you can play</Typography>
          </Box></Box>
        <IconButton onClick={() => setDrawer(false)} sx={{ position: "relative", right: -25, bottom: 0, p: 1.6 }}>
          <CloseIcon fontSize="large" />
        </IconButton>
      </Box>
      {currentUser && <Typography variant="h6" sx={{ mb: 3, px: 2, ...sideTextProps }}>Welcome, {currentUser?.firstName}!</Typography>}
      {!currentUser ?
        <List>
          {pathname !== "/pricing" && <ListItem button disablePadding component={Link} to="pricing">
            <ListItemText primary={"pricing"} primaryTypographyProps={sideTextProps} />
          </ListItem>}
          {pathname !== "/blog" && <ListItem button disablePadding component={Link} to="blog">
            <ListItemText primary={"blog"} primaryTypographyProps={sideTextProps} />
          </ListItem>}
          {pathname !== "/login" && <ListItem button component={Link} to="login" disablePadding>
            <ListItemText primary={"login"} primaryTypographyProps={sideTextProps} />
          </ListItem>}
          {pathname !== "/try" && <ListItem button disablePadding component={Link} to="try">
            <Button variant="contained" sx={{ borderRadius: 30, fontWeight: "500", px: 5, m: "auto", fontSize: "1.2rem" }}>Try It Free</Button>
          </ListItem>}
          <ListItem button disablePadding sx={{ mt: 2 }} component={"a"} href="https://www.bestimate.chat" target="_blank">
            <ListItemText primary={"support"} primaryTypographyProps={sideTextProps} />
          </ListItem>
          {pathname !== "/article" && <ListItem button disablePadding component={Link} to="article">
            <ListItemText primary={"research"} primaryTypographyProps={sideTextProps} />
          </ListItem>}
          <ListItem button disablePadding>
            <ListItemText primary={"careers"} primaryTypographyProps={sideTextProps} />
          </ListItem>
          <ListItem button disablePadding component={"a"} href="https://www.techtherapypodcast.com" target="_blank">
            <ListItemText primary={"podcast"} primaryTypographyProps={sideTextProps} />
          </ListItem>
          <Container sx={{ mt: 2 }}>
            {iconsPathList.map((val, i) => <Box component={"img"} sx={{ m: 1.8, cursor: "pointer" }} key={i} src={"/social/" + val} alt={val} />)}
          </Container>
        </List>
        : <List>
          <ListItem button onClick={handleOpen}>
            <ListItemText primary={"Profile Settings"} primaryTypographyProps={sideTextProps} />
          </ListItem>
          <ListItem button onClick={handleOpenUpgrade}>
            <ListItemText primary={"Upgrade to Done-For-You"} primaryTypographyProps={sideTextProps} />
          </ListItem>
          <ListItem button component={Link} to="/" onClick={handleSubmit} sx={{ justifyContent: "center" }}>
            <ListItemText primary={"Log out"} sx={{ flex: "0.1 1 auto" }} primaryTypographyProps={sideTextProps} />
            <ListItemIcon>
              {loading ? <CircularProgress /> : <ExitToAppIcon color="primary" />}
            </ListItemIcon>
          </ListItem>
        </List>}
    </Box>
  );

  return (
    <Container //disableGutters={matchesNav} 
      maxWidth={"xl"}
      sx={{ flexGrow: 1, py: 1, display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap", }}>
      {pathname !== "/" && pathname !== "/login" && pathname !== "/try" && pathname !== "/blog" && pathname !== "/pricing" && pathname !== "/privacy" && pathname !== "/terms" && pathname !== "/article" ? <Box sx={{ display: "flex", alignItems: "center" }}>
        <img alt="site-logo" src="/bestimate 2.png" style={{ cursor: "pointer" }} onClick={() => setDrawer(true)} />
        {!edit && <Box sx={{ px: 1, textAlign: "center", }} >
          <Typography variant="h4" fontWeight={"400"} sx={{ textDecoration: "none", color: theme => theme.palette.primary.main }} component={Link} to="/">Bestimate</Typography>
          <Typography variant="body2" fontWeight={"400"}>Simpler data so you can play</Typography>
        </Box>}
        <Box sx={{ px: 1, textAlign: "center" }}>
          <Typography variant="body2">Bestimate</Typography>
          <Box sx={{ borderRadius: "30px", border: theme => `1px solid ${theme.palette.primary.main}`, py: 0.5, px: 1.3 }}>
            <Typography variant="body1" color="primary" fontWeight={"bold"}>
              $500k-$1M
            </Typography>
          </Box>
        </Box>
        <Box sx={{ px: 1, textAlign: "center" }}>
          <Typography variant="body2">Certainty</Typography>
          <Box sx={{ p: 0.7 }}>
            <Typography variant="body1" fontWeight={"bold"}>
              84%
            </Typography>
          </Box>
        </Box>
      </Box> : <Box sx={{ display: "flex", alignItems: "center" }}>
        <img alt="site-logo" src="/bestimate 2.png" onClick={() => setDrawer(true)} style={{ cursor: "pointer", zIndex: 1 }} />
        <Box sx={{ px: 1, cursor: "pointer", textDecoration: "none", color: "#000000", zIndex: 1 }} component={Link} to="/">
          <Typography variant="h3" fontWeight={"400"}>Bestimate</Typography>
          <Typography variant="body1" fontWeight={"400"}>Simpler data so you can play</Typography>
        </Box></Box>}
      {/* {(!matches && pathname === "/") && <Box sx={{ px: 1, textAlign: "center", width: "40%" }}>
        <Typography variant="h6" fontWeight={"bold"}>The Best Estimate for Your Business</Typography>
        <Typography variant="body2">When performance is measured, performance improves. When performance is measured and reported back, the rate of improvement accelerates.</Typography>
      </Box>} */}
      {pathname !== "/" && pathname !== "/login" && pathname !== "/try" && pathname !== "/pricing" && pathname !== "/blog" && pathname !== "/terms" && pathname !== "/privacy" && pathname !== "/article" && <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ px: 1, textAlign: "center" }}>
          <Typography fontWeight={"500"} variant="body2" mb={1.3}>Completed</Typography>
          <Typography fontWeight={"bold"} variant="body1" color="primary" mb={1.3}>{emptyDataFields} of 12</Typography>
        </Box>
        {edit && <Box sx={{ px: 1, textAlign: "center" }}>
          <Typography fontWeight={"500"} variant="body2" >Cancel</Typography>
          <Box sx={{ borderRadius: "50%", border: theme => `1px solid ${theme.palette.primary.main}` }}>
            <IconButton sx={{ p: 1.1 }} onClick={handleCancel}>
              <CloseIcon color="primary" fontSize='small' />
            </IconButton>
          </Box>
        </Box>}
        <Box sx={{ position: "relative" }}>
          <Typography variant="body2" fontWeight={"500"} textAlign="center">Month</Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns} >
            <DatePicker
              views={['year', 'month']}
              minDate={new Date(`${new Date().getFullYear() - 3}-01-01`)}
              maxDate={new Date(`${new Date().getFullYear() + 3}-12-01`)}
              value={dateValue}
              onChange={(newValue) => {
                setDateValue(newValue)
              }}
              openTo="month"
              disabled={edit}
              OpenPickerButtonProps={{ sx: { position: "absolute", left: 0, width: "100%", "& svg": { visibility: "hidden" }, py: 1.3, zIndex: 1 } }}
              renderInput={(params) => <TextField required={false} {...params} value={format(dateValue, 'MMM yyyy')} helperText={null} sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: 30,
                  "&>div": {
                    "& .MuiButtonBase-root.MuiIconButton-root": {
                      borderRadius: 30
                    }
                  }
                }
              }} inputProps={{ sx: { fontWeight: "500", color: theme => theme.palette.primary.main, py: 1.3, pr: 0, pl: { xs: 0, md: "14px" }, textAlign: "center", width: "100px" } }} />}
            />
          </LocalizationProvider>
          {/* <Button variant="outlined" disabled={edit} sx={{ borderRadius: 30, fontWeight: "500", px: 5, textTransform: "capitalize" }}>May 2022</Button> */}
        </Box>

        {edit && <Box sx={{ px: 1, textAlign: "center" }}>
          <Typography variant="body2" fontWeight={"500"}>Save</Typography>
          <Box sx={{ borderRadius: "30px", border: theme => `1px solid ${theme.palette.primary.main}` }}>
            {(saveLoading && isSubmit) ? <IconButton sx={{ p: 1.1 }}>
              <CircularProgress size={20} />
            </IconButton> : <IconButton sx={{ p: 1.1 }} onClick={handleSave}>
              <DoneIcon color="primary" fontSize='small' />
            </IconButton>}
          </Box>
        </Box>}
        {!edit && <Box sx={{ px: 1, textAlign: "center" }}>
          <Typography variant="body2" fontWeight={"500"}>Edit</Typography>
          <Box sx={{ borderRadius: "30px", border: theme => `1px solid ${theme.palette.primary.main}` }}>
            <IconButton sx={{ p: 1.1 }} onClick={_ => setEdit(true)}>
              <EditIcon color="primary" fontSize='small' />
            </IconButton>
          </Box>
        </Box>}

        <Box sx={{ px: 1, }}>
          <Typography variant="body2" fontWeight={"500"} sx={{ ml: 1.5 }}>Compare to</Typography>
          <Box sx={{ borderRadius: "30px", border: theme => `1px solid ${theme.palette.primary.main}`, py: 0.8, px: 1.5, width: 150 }}>
            <Typography variant="body1" color="primary" fontWeight={"bold"}>
              Your Average
            </Typography>
          </Box>
        </Box>
      </Box>}
      {(pathname === "/" || pathname === "/pricing" || pathname === "/blog" || pathname === "/article") && <Box sx={{ display: "flex", alignItems: "center", flexDirection: { xs: "column", md: "row" }, pr: { xs: 1, md: 0 } }}>
        {matches ? <IconButton onClick={() => setDrawer(true)}>
          <MenuIcon fontSize="large" />
        </IconButton> : <Box>
          {pathname !== "/pricing" && <Button variant="text" component={Link} to="pricing" sx={{ borderRadius: 30, fontWeight: "500", px: 3, mr: 0.5, fontSize: "18px" }} color="primary">pricing</Button>}
          {pathname !== "/blog" && <Button variant="text" component={Link} to="blog" sx={{ borderRadius: 30, fontWeight: "500", px: 3, mr: 0.5, fontSize: "18px" }} color="primary">blog</Button>}
          <Button variant="text" component={Link} to="login" sx={{ borderRadius: 30, fontWeight: "500", px: 3, mr: 0.5, fontSize: "18px" }} color="primary">Login</Button>
          <Button variant="contained" component={Link} to="try" sx={{ borderRadius: 30, fontWeight: "500", px: 3, fontSize: "18px" }}>Try it free</Button>
        </Box>}
      </Box>
      }
      <Drawer
        variant="temporary"
        anchor="left"
        open={drawer}
        onClose={() => setDrawer(false)}
      >
        <ListBasic />
      </Drawer>
      <Modal
        open={open}
        onClose={handleClose}
        aria-describedby="aria-modal-description"
        aria-labelledby="aria-modal-title"
      >
        <Settings handleClose={handleClose} currentUser={currentUser} />
      </Modal>
      <Modal
        open={openUpgrade}
        onClose={handleCloseUpgrade}
        aria-describedby="aria-modal-description"
        aria-labelledby="aria-modal-title"
      >
        <Upgrade handleClose={handleCloseUpgrade} />
      </Modal>

    </Container>
  )
}

export default Navbar
