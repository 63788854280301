import React, { useEffect, useState } from "react";
import { Box, Typography, Container, Button, TextField, Grid, Stack, Alert, CircularProgress } from "@mui/material"
import { Link } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles"
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useRegister } from "../../hooks/useRegister";


const FormBox = styled(Box)(({ theme }) => ({
  borderRadius: "10px",
  border: "1px solid #B0B4B4",
  boxSizing: "border-box",
  marginTop: `${theme.spacing(2)}`,
  padding: `${theme.spacing(3)}`,

  "& .MuiFormControl-root:not(:first-of-type)": {
    marginTop: theme.spacing(2)
  }
}));


const Signup = () => {
  const { signup, error, loading } = useRegister();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate()
  const { palette: { mode } } = useTheme()
  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    online: true,
    displayName: "",
  }

  const [state, setState] = useState(initialState)

  const handleChange = (evt) => {
    const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value
    });
  };

  const hanldeSubmit = () => {
    let { ...others } = state
    if (!state.firstName || !state.lastName || !state.email || !state.password) {
      return enqueueSnackbar("Please fill the all the fields", { variant: "error" });
    }
    let user = { ...others, displayName: state.firstName + " " + state.lastName }
    signup(user, navigate)
  }

  useEffect(() => {
    return () => {
      if (error?.message && error?.message?.variant !== "success") {
        // dispatch(removeerror?.message())
      }
    }
  }, [error?.message])


  return (
    <Container >
      <Grid container spacing={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center", my: 5 }}>
        <Grid item xs={12} sm={7} md={5}>
          <Typography variant="h1" sx={{ mb: 2, fontSize: "1.8rem", fontWeight: "bold", textAlign: "center" }}>Registration</Typography>
          {error?.message && (
            <Stack spacing={2} sx={{ mt: 2 }}>
              <Alert
                severity={error?.message?.variant}
                variant={mode === "dark" ? "filled" : "standard"}
                icon={false}
              // onClose={_ => dispatch(removeerror?.message())}
              >
                {error?.message?.toString()}
              </Alert>
            </Stack>
          )}
          <FormBox>
            <TextField
              id="loginEmail"
              label="Email"
              variant="outlined"
              size="small"
              fullWidth
              name="email"
              value={state.email}
              onChange={handleChange}
            />
            <TextField
              label="First Name"
              variant="outlined"
              size="small"
              fullWidth
              name="firstName"
              value={state.firstName}
              onChange={handleChange}
            />
            <TextField
              label="Last Name"
              variant="outlined"
              size="small"
              fullWidth
              name="lastName"
              value={state.lastName}
              onChange={handleChange}
            />
            <TextField
              id="loginPass"
              label="Password"
              variant="outlined"
              size="small"
              fullWidth
              type={"password"}
              name="password"
              value={state.password}
              onChange={handleChange}
            />
            <Button variant="contained" fullWidth sx={{ fontWeight: "bold", mt: 1.5 }} color="primary" onClick={hanldeSubmit} disabled={loading} startIcon={loading && <CircularProgress size={20} color="secondary" />}>Continue</Button>

          </FormBox>
          <Typography variant="body2" sx={{ fontWeight: "bold", my: 1.5, textAlign: "end", color: theme => theme.subText }}>Have an account?&nbsp;
            <Typography variant="body2" component={Link} sx={{ color: theme => theme.subText, fontWeight: "bold" }} to="/login">
              Login
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </Container >
  )
}

export default Signup;