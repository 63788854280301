import { Box, Button, Grid, Typography } from "@mui/material"
import { Container } from "@mui/system"
import React from "react"
import Footer from "../components/Footer"
import { dashboardPricing, enterPricing, techPricing } from "./const"

const listStyles = {
    listStyle: "none",
    py: 1,
    "&:before": {
        content: '"·"',
        fontSize: "30px",
        verticalAlign: "sub",
        lineHeight: "20px",
        position: "absolute",
        left: 0,
    },
}

const Pricing = () => {
    return (
        <Container maxWidth={"xl"}>
            <Grid container spacing={{ xl: 20, md: 10, xs: 0 }}>
                <Grid item xs={12} md={4}>
                    <Box
                        sx={{
                            my: 3,
                            mx: 2,
                            textAlign: "center",
                            border: "0.5px solid #042187",
                            boxShadow: "0px 25px 50px rgba(22, 25, 79, 0.05)",
                            borderRadius: "50px",
                            px: 3,
                            pt: 4,
                            pb: 3,
                        }}
                    >
                        <Typography variant="h4">Dashboard</Typography>
                        <Box
                            sx={{
                                py: 5,
                            }}
                        >
                            <Typography variant="h3" display={"inline"} fontWeight={"400"}>
                                $30
                            </Typography>
                            <Typography variant="h5" display={"inline"} fontWeight={"400"}>
                                /Mo
                            </Typography>
                        </Box>
                        <Typography variant="body1" mb={6} mt={2}>
                            Free for 30 days, cancel any time
                        </Typography>
                        <Button
                            variant="outlined"
                            color="primary"
                            sx={{ borderRadius: "20px", py: 1.5, px: 2 }}
                        >
                            Get Started
                        </Button>
                        <Box component="ul" textAlign="left" px={2.5} position="relative">
                            {dashboardPricing.map((v, i) => (
                                <Typography
                                    key={i}
                                    variant="p"
                                    component="li"
                                    color="primary"
                                    sx={listStyles}
                                >
                                    {v}
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box sx={{ textAlign: "center" }}>
                        <Box
                            sx={{
                                my: 3,
                                mx: 2,
                                textAlign: "center",
                                border: "0.5px solid #042187",
                                boxShadow: "0px 25px 50px rgba(22, 25, 79, 0.05)",
                                borderRadius: "50px",
                                px: 3,
                                pt: 4,
                                pb: 3,
                            }}
                        >
                            <Typography variant="h4">Tech Therapy</Typography>
                            <Box
                                sx={{
                                    py: 5,
                                }}
                            >
                                <Typography variant="h3" display={"inline"} fontWeight={"400"}>
                                    $3,600
                                </Typography>
                                <Typography variant="h5" display={"inline"} fontWeight={"400"}>
                                    /Mo
                                </Typography>
                            </Box>
                            <Typography variant="body1" mb={6} mt={2}>
                                +$7,200 setup
                            </Typography>
                            <Button
                                variant="outlined"
                                color="primary"
                                sx={{ borderRadius: "20px", py: 1.5, px: 2 }}
                            >
                                Get Started
                            </Button>
                            <Typography
                                variant="body2"
                                color="primary"
                                fontWeight={"500"}
                                mt={3}
                            >
                                Everything on Dashboard, plus:
                            </Typography>
                            <Box component="ul" textAlign="left" px={2.5} position="relative">
                                {techPricing.map((v, i) => (
                                    <Typography
                                        key={i}
                                        variant="p"
                                        component="li"
                                        color="primary"
                                        sx={listStyles}
                                    >
                                        {v}
                                    </Typography>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box sx={{ textAlign: "center" }}>
                        <Box
                            sx={{
                                my: 3,
                                mx: 2,
                                textAlign: "center",
                                border: "0.5px solid #042187",
                                boxShadow: "0px 25px 50px rgba(22, 25, 79, 0.05)",
                                borderRadius: "50px",
                                px: 3,
                                pt: 4,
                                pb: 3,
                            }}
                        >
                            <Typography variant="h4">Enterprise</Typography>
                            <Box
                                sx={{
                                    py: 5,
                                }}
                            >
                                <Typography variant="h3" display={"inline"} fontWeight={"400"}>
                                    $7,200
                                </Typography>
                                <Typography variant="h5" display={"inline"} fontWeight={"400"}>
                                    /Mo
                                </Typography>
                            </Box>
                            <Typography variant="body1" mb={6} mt={2}>
                                Free for 30 days, cancel any time
                            </Typography>
                            <Button
                                variant="outlined"
                                color="primary"
                                sx={{ borderRadius: "20px", py: 1.5, px: 2 }}
                            >
                                Get Started
                            </Button>
                            <Box component="ul" textAlign="left" px={2.5} position="relative">
                                {enterPricing.map((v, i) => (
                                    <Typography
                                        key={i}
                                        variant="p"
                                        component="li"
                                        color="primary"
                                        sx={listStyles}
                                    >
                                        {v}
                                    </Typography>
                                ))}
                            </Box>
                            <Typography
                                variant="body2"
                                color="primary"
                                fontWeight={"500"}
                                mt={10}
                            >
                                Book a FREE Strategy Call today to get started.
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Footer />
        </Container>
    )
}

export default Pricing
