
import styled from '@emotion/styled';
import { Box, Typography, Grid, Button } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
import { forwardRef } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: "30px",
    boxShadow: 24,
    p: 2,
    maxWidth: 720,
    width: { xs: "90%" },
    maxHeight: 540,
}
const Li = styled('li')({
    fontWeight: 400,
    listStyle: "none",
    "&:before": {
        content: '"·"',
        fontSize: "30px",
        verticalAlign: "sub",
        lineHeight: "20px",
        marginRight: 10
    }
})
const Ul = styled('ul')({
    paddingInlineStart: "0.5rem"
})

const Upgrade = forwardRef(({ handleClose }, ref) => {
    return (<Box sx={style} ref={ref} tabIndex={1}>
        <Box sx={{
            display: "flex", justifyContent: "center"
        }}>
            {/* <IconButton onClick={handleClose}><CloseIcon /></IconButton> */}
            <Typography variant="h5" fontWeight={"500"}>Upgrade to Done-For-You</Typography>
        </Box>

        <Grid container spacing={2} sx={{ p: 2, maxHeight: 500, overflowY: { xs: "auto", md: "hidden" }, }}>
            <Grid item xs={12} textAlign="center">
                <Button variant="outlined" sx={{ px: 6, py: 1, borderRadius: "30px", flexDirection: "column" }} >
                    <Typography variant="h5" fontWeight={"500"} >Schedule Demo</Typography>
                    <Typography variant="body1" fontWeight={"500"} >
                        For more information
                    </Typography>
                </Button>
            </Grid>
            <Grid item xs={12} md={6}  >
                <Typography variant="subtitle1" fontWeight="500">Are you happy with your numbers?</Typography>
                <Ul>
                    <Li>Know your company’s valuation?</Li>
                    <Li>Know your 2-year numbers?</Li>
                    <Li>Get great customer retention?</Li>
                    <Li>Have low costs per sale?</Li>
                    <Li>Get tons of leads?</Li>
                    <Li>Have a high conversion rate?</Li>
                    <Li>Have a very lean operation?</Li>
                    <Li>Charge enough for your services?</Li>
                    <Li>Get tons of web traffic?</Li>
                    <Li>Have bug-free software systems?</Li>
                    <Li>Leave on time or early every day?</Li>
                    <Li>Pay yourself first, enough to retire on?</Li>
                </Ul>
                <Typography variant="subtitle1" fontWeight="500">If you said NO to any of these, get
                    your Bestimate Done-For-You!</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" fontWeight="500">Bestimate Done-For-You includes:</Typography>
                <Ul>
                    <Li>Hands-on help for your 2-year numbers</Li>
                    <Li>Weekly calls with YOUR Fractional CTO</Li>
                    <Li>3 years of future business projections</Li>
                    <Li>Dozens of extra metrics in Bestimate</Li>
                    <Li>Premium Bestimate features forever</Li>
                    <Li>Monthly DFY Mastermind on Discord</Li>
                    <Li>90-day Profit First Business Planning</Li>
                    <Li>FREE marketing automation (Bento)</Li>
                    <Li>FREE SEO-first StoryBrand website</Li>
                    <Li>Discounted custom development</Li>
                    <Li>Syndicated Podcast Interview</Li>
                </Ul>
                <Typography variant="subtitle1" fontWeight="500">DFY Profit First Guarantee:
                    <br />
                    We increase your profit every
                    month or we work for FREE.</Typography>
            </Grid>

        </Grid>
    </Box>)
})

export default Upgrade;