import { useState, useEffect, forwardRef } from "react";
import { Box, Typography, IconButton, Grid, TextField, CircularProgress, Button } from '@mui/material';
import { useAuthContext } from '../hooks/useAuthContext'
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ImageTwoToneIcon from '@mui/icons-material/ImageTwoTone';
import { styled } from '@mui/material/styles';
import { useRegister } from "../hooks/useRegister";
import { bucket } from "../firebase/config";
import { ref as firebaseRef, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useSnackbar } from "notistack";
import { useCollection } from "../hooks/useCollection";
import TextMaskCustom from "./TaskMask"

const Input = styled('input')({
    display: 'none',
});
const Img = styled('img')({
    maxWidth: "-webkit-fill-available",
    maxHeight: "-webkit-fill-available"
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: "30px",
    boxShadow: 24,
    p: 2,
    maxWidth: 720,
    width: { xs: "90%" },
    maxHeight: 519,
}

const initialState = {
    email: "",
    name: "",
    startDate: "",
    firstName: "",
    lastName: "",
    website: "",
    zipCode: "",
    title: "",
    logo: null,
    phone: "",
    online: true
}

const Settings = forwardRef(({ handleClose, currentUser }, ref) => {
    const { enqueueSnackbar } = useSnackbar();
    const { addUser, addCompany, error, loading } = useRegister()
    const { documents } = useCollection("companies")
    const { user } = useAuthContext()

    const [state, setState] = useState({ ...initialState, ...currentUser })
    const [fileDataURL, setFileDataURL] = useState(null);

    const handleChange = (evt) => {
        const value = evt.target.name === "logo" ? evt.target.files[0] : evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value
        });
    };

    useEffect(() => {
        setState(prev => ({ ...prev, ...documents?.find(v => v.id === user.uid) }))
    }, [documents, user?.uid])

    useEffect(() => {
        let fileReader, isCancel = false;
        if (state.logo && typeof state.logo !== "string") {
            fileReader = new FileReader();
            fileReader.onload = (e) => {
                const { result } = e.target;
                if (result && !isCancel) {
                    setFileDataURL(result)
                }
            }
            fileReader.readAsDataURL(state.logo);
        }
        return () => {
            isCancel = true;
            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort();
            }
        }

    }, [state.logo]);

    const handleSubmit = async () => {
        try {
            const { firstName, lastName, title, phone, online, id, logo, ...others } = state
            const displayName = firstName + " " + lastName
            const requiredFields = ["name", "firstName", "email"]
            let checkValues = requiredFields.some(name => name = "logo" ? state[name]?.length === 0 : !state[name])
            if (checkValues) return enqueueSnackbar(`Please fill the required fields ${!state?.logo?.length ? "and image" : ""}`, { variant: "error" });

            addUser({ user: user }, { displayName, firstName, lastName, title, phone, online }, enqueueSnackbar)
            let downloadUrl = ""
            if (typeof state.logo !== "string") {
                const storageRef = firebaseRef(bucket, `companies/${id}/${logo.name}`);
                const uploadTask = uploadBytesResumable(storageRef, logo);
                uploadTask.on('state_changed', null,
                    (error) => {
                        enqueueSnackbar(error, { variant: "error" });
                    },
                    async () => {
                        downloadUrl = await getDownloadURL(uploadTask.snapshot.ref)
                        addCompany(user, { ...others, logo: downloadUrl }, enqueueSnackbar, handleClose)
                    }
                )

            } else {
                downloadUrl = state.logo
                addCompany(user, { ...others, logo: downloadUrl }, enqueueSnackbar, handleClose)
            }

        } catch (err) {
            console.log(err)
        }
    }


    useEffect(() => {
        return () => {
            if (error?.message) {
                enqueueSnackbar(error?.message, { variant: "error" });
            }
        }
    }, [error?.message, enqueueSnackbar])

    return (<Box sx={style} ref={ref} tabIndex="-1">
        <Box sx={{
            display: "flex", justifyContent: "space-between"
        }}>
            <IconButton onClick={handleClose}><CloseIcon /></IconButton>
            <Typography variant="h5" fontWeight={"500"}>Profile Settings</Typography>

            <IconButton onClick={handleSubmit}>{loading ? <CircularProgress size={20} /> : <CheckIcon />}</IconButton>
        </Box>
        <Grid container spacing={2} sx={{ p: 2, maxHeight: 500, overflowY: { xs: "auto", md: "hidden" }, }}>
            <Grid item xs={12} md={6}  >
                <Typography variant="h6">Company</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography variant="h6">User</Typography>
            </Grid>
            <Grid item container xs={12} md={6} alignItems="flex-end">
                <Grid item xs={4} >
                    <Typography variant="body1" fontWeight={"500"}>Name*</Typography>
                </Grid>
                <Grid item xs={8} >
                    <TextField
                        name="name"
                        label=""
                        variant="standard"
                        autoComplete='off'
                        inputProps={{ sx: { color: theme => theme.palette.primary.main, fontWeight: "500" } }}
                        value={state.name}
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
            <Grid item container xs={12} md={6} alignItems="flex-end">
                <Grid item xs={4}>
                    <Typography variant="body1" fontWeight={"500"}>Email</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        name="email"
                        label=""
                        disabled
                        variant="standard"
                        autoComplete='off'
                        inputProps={{ sx: { color: theme => theme.palette.primary.main, fontWeight: "500" } }}
                        value={state.email}
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
            <Grid item container xs={12} md={6} alignItems="flex-end" >
                <Grid item xs={4} >
                    <Typography variant="body1" fontWeight={"500"}>Start Date</Typography>
                </Grid>
                <Grid item xs={7} >
                    <TextField
                        name="startDate"
                        type="date"
                        label=""
                        variant="standard"
                        fullWidth
                        inputProps={{ sx: { color: theme => theme.palette.primary.main, fontWeight: "500" } }}
                        value={state.startDate}
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
            <Grid item container xs={12} md={6} alignItems="flex-end">
                <Grid item xs={4}>
                    <Typography variant="body1" fontWeight={"500"}>First Name*</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        name="firstName"
                        label=""
                        variant="standard"
                        autoComplete='off'
                        inputProps={{ sx: { color: theme => theme.palette.primary.main, fontWeight: "500" } }}
                        value={state.firstName}
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
            <Grid item container xs={12} md={6} alignItems="flex-end" >
                <Grid item xs={4} >
                    <Typography variant="body1" fontWeight={"500"}>Website</Typography>
                </Grid>
                <Grid item xs={8} >
                    <TextField
                        name="website"
                        label=""
                        variant="standard"
                        autoComplete='off'
                        inputProps={{ sx: { color: theme => theme.palette.primary.main, fontWeight: "500" } }}
                        value={state.website}
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
            <Grid item container xs={12} md={6} alignItems="flex-end">
                <Grid item xs={4}>
                    <Typography variant="body1" fontWeight={"500"}>Last Name</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        name="lastName"
                        label=""
                        variant="standard"
                        autoComplete='off'
                        inputProps={{ sx: { color: theme => theme.palette.primary.main, fontWeight: "500" } }}
                        value={state.lastName}
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
            <Grid item container xs={12} md={6} alignItems="flex-end" >
                <Grid item xs={4} >
                    <Typography variant="body1" fontWeight={"500"}>ZIP Code</Typography>
                </Grid>
                <Grid item xs={8} >
                    <TextField
                        name="zipCode"
                        label=""
                        variant="standard"
                        autoComplete='off'
                        inputProps={{ sx: { color: theme => theme.palette.primary.main, fontWeight: "500" } }}
                        value={state.zipCode}
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
            <Grid item container xs={12} md={6} alignItems="flex-end">
                <Grid item xs={4}>
                    <Typography variant="body1" fontWeight={"500"}>Title </Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        name="title"
                        label=""
                        variant="standard"
                        autoComplete='off'
                        inputProps={{ sx: { color: theme => theme.palette.primary.main, fontWeight: "500" } }}
                        value={state.title}
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
            <Grid item container xs={12} md={6} alignItems="flex-start" sx={{ my: 2 }}>
                <Grid item xs={4}>
                    <Typography variant="body1" fontWeight={"500"}>Logo </Typography>
                </Grid>
                <Grid item xs={8}>
                    <label htmlFor="icon-button-file">
                        <Input accept="image/*" id="icon-button-file" name="logo" type="file" onChange={handleChange} />
                        <IconButton sx={{ width: { xs: "80px", md: "200px" }, height: { xs: "80px", md: "200px" }, backgroundColor: !state.logo && "#F1F1F1" }} component="span" >
                            {!!state.logo ? <Img src={typeof state.logo !== "string" ? fileDataURL : state?.logo} alt="logo" />
                                : <ImageTwoToneIcon sx={{ fontSize: 100 }} />
                            }
                        </IconButton>
                    </label>
                </Grid>
            </Grid>
            <Grid item container xs={12} md={6} alignItems="flex-start" sx={{ my: 2 }}>
                <Grid item xs={4}>
                    <Typography variant="body1" fontWeight={"500"}>Phone </Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        name="phone"
                        label=""
                        variant="standard"
                        autoComplete='off'
                        inputProps={{ sx: { color: theme => theme.palette.primary.main, fontWeight: "500" } }}
                        value={state.phone}
                        onChange={handleChange}
                        InputProps={{ inputComponent: TextMaskCustom }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Billing</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body1" fontWeight={"500"} sx={{ pt: 1 }}>Do-It-Yourself Plan   $30/mo </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Button variant="text">Upgrade</Button>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body1" fontWeight={"500"} >Next Billing Date </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body1" fontWeight={"500"} >June 30, 2022</Typography>
                </Grid>
            </Grid>
        </Grid>
    </Box>)
})

export default Settings;